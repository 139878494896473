/*!
 *  - index.js
 * @generated Thu Dec 15 2022 16:27:58 GMT+0900 (GMT+09:00)
 */
'use strict';

(function ($, FUNCTIONS, cssua) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		mainSlide: function mainSlide() {
			var thisCategory = void 0,
			    thisTime = void 0,
			    thisTitle = void 0,
			    thisLead = void 0,
			    thisTag = void 0,
			    thisLink = void 0,
			    thisTarget = void 0;

			$('.index-main-slide').slick({
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"><i class="icon icon-arrow_left"></i></a>',
				nextArrow: '<a class="slick-next" href="#"><i class="icon icon-arrow_right"></i></a>',
				dots: true,
				appendDots: $('.index-main-controlArea'),
				responsive: [{
					breakpoint: 768,
					settings: {
						appendArrows: $('.index-main-controlArea'),
						dots: false
					}
				}]
			});

			//初期表示
			slideTextAdd();
			//スライダー切り替わり時
			$('.index-main-slide').on('afterChange', function (slick, currentSlide) {
				slideTextAdd();
			});

			function slideTextAdd() {
				// thisCategory = $('.index-main-slide .slick-active .index-main-imgWrap .js-main-slide-category-hidden').text();
				// thisTime = $('.index-main-slide .slick-active .index-main-imgWrap .js-main-slide-time-hidden').text();
				thisTitle = $('.index-main-slide .slick-active .index-main-imgWrap .js-main-slide-title-hidden').text();
				thisLead = $('.index-main-slide .slick-active .index-main-imgWrap .js-main-slide-lead-hidden').text();
				// thisTag = $('.index-main-slide .slick-active .index-main-imgWrap .js-main-slide-tag-hidden').text();
				thisLink = $('.index-main-slide .slick-active .index-main-imgWrap').attr('href');
				thisTarget = $('.index-main-slide .slick-active .index-main-imgWrap').attr('target');
				// $('.js-main-slide-category').text(thisCategory);
				// $('.js-main-slide-time').text(thisTime);
				$('.js-main-slide-title').text(thisTitle);
				$('.js-main-slide-lead').text(thisLead);
				// $('.js-main-slide-tag').text(thisTag);
				$('.js-main-slide-link').attr('href', thisLink);
				$('.js-main-slide-link').attr('target', thisTarget);
				$('.js-main-slide-link-lead').attr('href', thisLink);
				$('.js-main-slide-link-lead').attr('target', thisTarget);

				// if($('.index-main-slide .slick-active .index-main-imgWrap').hasClass('index-main-imgWrap-feature')) {
				// 	$('.index-main-textArea').removeClass('index-main-textArea-column');
				// 	$('.index-main-textArea').removeClass('index-main-textArea-products');
				// 	$('.index-main-textArea').addClass('index-main-textArea-feature');
				// }else if($('.index-main-slide .slick-active .index-main-imgWrap').hasClass('index-main-imgWrap-column')) {
				// 	$('.index-main-textArea').removeClass('index-main-textArea-feature');
				// 	$('.index-main-textArea').removeClass('index-main-textArea-products');
				// 	$('.index-main-textArea').addClass('index-main-textArea-column');
				// }else if($('.index-main-slide .slick-active .index-main-imgWrap').hasClass('index-main-imgWrap-products')) {
				// 	$('.index-main-textArea').removeClass('index-main-textArea-feature');
				// 	$('.index-main-textArea').removeClass('index-main-textArea-column');
				// 	$('.index-main-textArea').addClass('index-main-textArea-products');
				// }
			}
		},
		seasonalSlide: function seasonalSlide() {
			if ($('.index-seasonal-slide').length) {
				$('.index-seasonal-slide').slick({
					infinite: true,
					autoplay: true,
					slidesToShow: 2,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev" href="#"><i class="icon icon-arrow_left"></i></a>',
					nextArrow: '<a class="slick-next" href="#"><i class="icon icon-arrow_right"></i></a>',
					dots: false,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1
						}
					}]
				});
				var heightArray = [];
				for (var i = 0; i < $('.index-seasonal-textArea').length; i++) {
					heightArray.push($('.index-seasonal-textArea').eq(i).outerHeight());
				}
				var textareaMinHeight = heightArray.reduce(function (a, b) {
					return Math.max(a, b);
				});
				$('.index-seasonal-textArea').css('min-height', textareaMinHeight);

				$('.index-seasonal-slide').on('afterChange', function (slick, currentSlide) {
					$('.index-seasonal-slide .slick-active').each(function (idx, ele) {
						var $this = $(ele);
						if (!$this.find('.lazy-imgWrap').hasClass('is-loaded')) {
							var url = $this.find('.lazy-imgWrap img').data('src');
							$this.find('.lazy-imgWrap').addClass('is-loaded');
							$this.find('.lazy-imgWrap img').removeClass('lazy');
							$this.find('.lazy-imgWrap img').attr('src', url);
						}
					});
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.mainSlide();
			_this.seasonalSlide();
		}
	};

	$(function () {
		return FUNCTIONS.loadDelayScript();
	});
})(window.jQuery, window.FUNCTIONS, window.cssua);